import Vue from "vue";
import { mdiFileDocumentOutline, mdiClipboard } from "@mdi/js";

export default Vue.extend({
  name: "BanorteComponent",
  props: {
    infoBancaria: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        ticket: mdiFileDocumentOutline,
        op: mdiClipboard,
      } as Record<string, string>,
    };
  },
});
